











































import { Component, Vue } from 'vue-property-decorator'
import AssociationsMenu from '@/layouts/AssociationsMenu.vue'

import axios from 'axios'

@Component({
  components: {
    AssociationsMenu
  }
})
export default class AssociationAttachmentsTypesIndex extends Vue {
  filteredData = []
  types = []

  created () {
    axios.get('user/association/attachment/type')
      .then(response => {
        this.filteredData = this.types = response.data.data
      })
  }

  search (searchString: string) {
    this.filteredData = this.types.filter((type: Record<string, any>) => {
      let { name, category } = type
      name = name.toLowerCase()
      category = category.toLowerCase()
      searchString = searchString.toLowerCase()

      if (name.includes(searchString) || category.includes(searchString)) {
        return document
      }
    })
  }
}
